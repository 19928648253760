import './App.css';
import React, { useEffect } from "react";
import { Timer, Settings } from './components';
import { Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';

function App() {

  useEffect(() => {
    ReactGA.initialize('UA-243906140-1');
    ReactGA.pageview('home');
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/settings" element={ <Settings /> } />
        <Route exact path="/" element={ <Timer /> } />
      </Routes>
    </div>
  );
}

export default App;
