import { useEffect, useState } from "react";

const useTickingNow = () => {
  const [ now, setNow ] = useState(new Date());

  useEffect(() => {
    setInterval(() => { 
      setNow(new Date());
    }, 1000)
  })

  return now;
}

export default useTickingNow;