import React, { useState } from "react";

const Settings = () => {
  const [ showStrong, setShowStrong ] = useState(localStorage.getItem("strong-ai"));
  return(
    <div className="Settings">
      <p className="SettingsLabel">Settings</p>
      <div className="Question">
        <p 
          className={showStrong ? "SelectedOption" : "Option"} 
          onClick={
            () => {
                localStorage.setItem("strong-ai", true); 
                setShowStrong(true);
              }
            }>
          Strong AI
        </p>
        <p 
          className={!showStrong ? "SelectedOption" : "Option"} 
          onClick={
            () => {
                localStorage.setItem("strong-ai", false); 
                setShowStrong(false);
              }
            }>
          Weak AI
        </p>
      </div>
    </div>
  );
}

export default Settings;