import React from "react";

import useFetchEndDate from "../hooks/useFetchEndDate";
import useTickingNow from "../hooks/useTickingNow";

const getTimeLeft = (now, endDate) => {
  const difference = endDate - now;
  
  const years = Math.floor((difference / (1000 * 60 * 60 * 24 * 365)));
  const months = Math.floor((difference / (1000 * 60 * 60 * 24) % 365 / 30.5));
  const days = Math.floor((difference / (1000 * 60 * 60 * 24) % 365 % 30.5));
  const hours = Math.floor((difference / (1000 * 60 * 60) % 24));
  const minutes = Math.floor((difference / (1000 * 60) % 60));
  const seconds = Math.floor((difference / 1000) % 60);

  const timeLeft = {
    "years" : { 
      "value": years, 
      "units": years === 1 ? "YEAR" : "YEARS",
    },
    "months" : { 
      "value": months, 
      "units": months === 1 ? "MONTH" : "MONTHS",
    },
    "days" : { 
      "value": days, 
      "units": days === 1 ? "DAY" : "DAYS",
    },
    "hours" : { 
      "value": hours, 
      "units": hours === 1 ? "HOUR" : "HOURS",
    },
    "minutes" : { 
      "value": minutes, 
      "units": minutes === 1 ? "MINUTE" : "MINUTES",
    },
    "seconds" : { 
      "value": seconds, 
      "units": seconds === 1 ? "SECOND" : "SECONDS",
    }
  };

  return timeLeft;
}

const Timer = () => {
  const endDate = useFetchEndDate();
  const now = useTickingNow();

  if (!endDate) return <div />;

  const timeLeft = getTimeLeft(now, endDate);

  return(
    <div className="Timer">
      <div className="Countdown">
        <div className="Number">{ timeLeft.years.value }</div><div className="Unit">{ timeLeft.years.units },</div>
        <br />
        <div className="Number">{ timeLeft.months.value }</div><div className="Unit">{ timeLeft.months.units },</div>
        <br />
        <div className="Number">{ timeLeft.days.value }</div><div className="Unit"> { timeLeft.days.units },</div>
        <br />
        <div className="Number">{ timeLeft.hours.value }</div><div className="Unit"> { timeLeft.hours.units },</div>
        <br />
        <div className="Number">{ timeLeft.minutes.value }</div><div className="Unit"> { timeLeft.minutes.units },</div>
        <br />
        <div className="Number">{ timeLeft.seconds.value }</div><div className="Unit"> { timeLeft.seconds.units }</div>
      </div>
      <div className="Label">
        until <a href="https://www.metaculus.com/questions/3479/date-weakly-general-ai-system-is-devised/">AGI</a>
      </div>
    </div>
  );
}

export default Timer;