import { useEffect, useState } from "react";

const getEndDate = (question) => {
  if (!question) {
    return null;
  }

  console.log(question);
  const normedMedian =
    question.aggregations.metaculus_prediction.latest.centers[0];
  const ratio = question.possibilities.scale.deriv_ratio;

  const startDate = new Date(question.possibilities.scale.min);
  const endDate = new Date(question.possibilities.scale.max);

  const width = endDate.getTime() - startDate.getTime();

  const denormedMedian = (ratio ** normedMedian - 1) / (ratio - 1);

  const result = new Date(startDate.getTime() + width * denormedMedian);

  return result;
};

const useFetchEndDate = () => {
  const [question, setQuestion] = useState();

  const fetchQuestion = () => {
    fetch("https://aiclock-proxy.herokuapp.com")
      .then((res) => res.json())
      .then(
        (result) => {
          setQuestion(result.question);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    fetchQuestion();
    setInterval(fetchQuestion, 1000 * 60 * 60);
  }, []);

  return getEndDate(question);
};

export default useFetchEndDate;
